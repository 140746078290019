<template>
    <b-table-simple responsive fixed>
        <thead>
            <tr>
                <th><Ellipsis>Tabela</Ellipsis></th>
                <th><Ellipsis>Convênios</Ellipsis></th>
                <th width="200px"><Ellipsis>Tipo</Ellipsis></th>
                <th width="200px"></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(table, index) in tables" :key="index" @click="() => incrementHiddenMoveItemsCount(table)">
                <td><Ellipsis>{{ table.name }}</Ellipsis></td>
                <td>
                    <Ellipsis v-if="table.clinic_health_plans?.length">{{ getHealthPlans(table)}}</Ellipsis>  
                    <span v-else>-</span>
                </td>
                <td>{{ parseTypeToLabel(table.type) }}</td>
                <td>
                    <div class="actions">
                        <MoveDown 
                            v-if="hiddenMoveItemsCount >= 10 && hiddenMoveItemsFeatTableId === table.id"
                            class="icon" 
                            v-b-tooltip.hover.left="'Clonar itens em outras tabelas'"
                            @click="() => cloneAndMoveItems(table.id)"
                        />
                        <div class="toggle-wrapper" @click="$event => {}">
                            <toggle-button
                                v-if="table.clinic_id"
                                :width="35"
                                v-model="table.active"
                                :sync="true"
                                :height="22"
                                :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
                                :disabled="loading"
                                @change="$event => updateActive(table.id, $event)"
                                v-b-tooltip.hover :title="table.active ? 'Desativar tabela' : 'Ativar tabela'"
                            />
                        </div>
                        <Edit 
                            v-if="table.clinic_id" 
                            class="icon" 
                            v-b-tooltip.hover title="Editar tabela"
                            @click="$emit('edit', table)"
                        />
                        <Delete 
                            v-if="table.clinic_id" 
                            class="icon" 
                            v-b-tooltip.hover title="Excluir tabela"
                            @click="() => deleteTable(table)"
                        />
                        <div 
                            v-if="!table.clinic_id"
                            class="system-table"
                            v-b-tooltip.hover title="Tabelas do sistema não podem ser editadas ou removidas"
                        >
                            Tabela do sistema
                        </div>
                        |
                        <a :href="`/tables/d/${table.id}`">
                            <List 
                                class="icon"  
                                v-b-tooltip.hover title="Ver itens da tabela"
                            />
                        </a>
                    </div>
                </td>
            </tr>
        </tbody>
    </b-table-simple>
</template>
<script>
import api from '@tables/api'
import * as ITEM from '@items/utils/constants'
import { parseTypeToLabel } from '@tables/utils/constants'

export default {
    props: {
        tables: {
            type: Array,
            required: true
        }
    },
    components: {
        Ellipsis: () => import('@/components/General/Ellipsis'),
        Edit: () => import('@/assets/icons/edit.svg'),
        Delete: () => import('@/assets/icons/delete.svg'),
        List: () => import('@tables/assets/icons/list.svg'),
        MoveDown: () => import('@tables/assets/icons/move-down.svg'),
    },
    data() {
        return {
            loading: false,
            hiddenMoveItemsFeatTableId: null,
            hiddenMoveItemsCount: 0
        }
    },
    methods: { 
        parseTypeToLabel, 
        incrementHiddenMoveItemsCount(table) {
            if (table.type !== ITEM.ITEM_TYPE_PROCEDURE) return;
            if (this.hiddenMoveItemsFeatTableId !== table.id) {
                this.hiddenMoveItemsCount = 0
            }
            this.hiddenMoveItemsFeatTableId = table.id
            this.hiddenMoveItemsCount++
        },
        async updateActive(id, $event) {
            this.loading = true
            try {
                await api.updateTable(id, { active: $event.value })
                this.$toast.success(`Item ${$event.value ? 'ativado' : 'desativado'} com sucesso!`)
            } catch (error) {
                this.$toast.error(error.message)
            } finally {
                this.loading = false
            }
        }, 
        deleteTable(table) {
            this.$swal({
                icon: 'question',
                html: `<div class="body-alert">Deseja realmente excluir a tabela <span>${table.name}</span>?</div>`,
                showCancelButton: true,
                confirmButtonColor: '#F63220',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                reverseButtons: true
            }).then(res => {
                if (res.isConfirmed) {
                    this.$emit('delete', table)
                }
            })
        },
        getHealthPlans(table){
            const healthPlanNames = table.clinic_health_plans.map(plan => {
                return plan.clinic_health_plan?.health_plan.fantasy_name;
            });
                
            return healthPlanNames.join(', ');
        },
        cloneAndMoveItems(tableId) {
            this.$swal({
                icon: 'warning',
                html: `<div class="body-alert">Deseja realmente clonar os itens desta tabela em todas as outras tabelas sem TISS ou SUS ativo? Essa ação pode levar alguns minutos.</div>`,
                showCancelButton: true,
                confirmButtonColor: '#F63220',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                reverseButtons: true
            }).then(res => {
                if (res.isConfirmed) {
                    api.cloneAndMoveItemsToOtherTables(tableId)
                        .then(() => {
                            this.$toast.success('Processo iniciado com sucesso!')
                        })
                        .catch(error => {
                            this.$toast.error(error.message)
                        })
                        .finally(() => {
                            this.hiddenMoveItemsFeatTableId = null
                            this.hiddenMoveItemsCount = 0
                        })
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.system-table {
    color: var(--type-placeholder);
    font-size: 0.8rem;
}
</style>